import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CheckFilledIcon,
  CheckFilledIconCircle,
  CheckIcon,
  CheckIconCircle,
} from "ui";

export const Choice = ({
  checked: isChecked,
  children,
  labelStyles,
  controlled = false,
  openEnded = false,
  onChange,
  view = "regular",
  variant = "single",
  disabled,
  choiceInputPlaceholderLabel,
}: {
  view?: "dropdown" | "regular";
  checked?: boolean;
  children: React.ReactNode;
  labelStyles?: React.CSSProperties;
  controlled?: boolean;
  openEnded?: boolean;
  onChange?: (checked: boolean, answer?: string) => void;
  variant?: "single" | "multiple";
  disabled?: boolean;
  choiceInputPlaceholderLabel?: string;
}) => {
  const [checked, setChecked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setChecked(isChecked || false);
  }, [isChecked]);

  const isRegular = view === "regular";

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        backgroundColor: isRegular
          ? checked
            ? "var(--mui-palette-options-active-background-color)"
            : "var(--mui-palette-options-background-color)"
          : "none",
        color: "var(--mui-palette-options-text-color)",
        marginLeft: 0,
        borderRadius: isRegular
          ? "var(--mui-palette-options-corners-rounding)"
          : 0,
        border: isRegular
          ? checked
            ? "solid 1px var(--mui-palette-options-active-border-color)"
            : "solid 1px var(--mui-palette-options-border-color)"
          : "none",
        overflow: "hidden",
        ...(isRegular && {
          ":hover": {
            border: "solid 1px rgba(27, 30, 61, 0.1)",
            backgroundColor:
              "var(--mui-palette-options-hover-background-color)",
          },
        }),
        "& .MuiSvgIcon-root": { fontSize: 100 },
        label: { marginLeft: 0 },
        ".Mui-checked": {
          color: "red",
        },
        ".MuiCheckbox-root": {
          padding: 0,
        },
      }}
      onClick={() => {
        if (disabled) return;
        if (controlled) {
          return onChange?.(!checked);
        }
        onChange?.(!checked);
        setChecked(!checked);
      }}
    >
      <FormControlLabel
        sx={{
          width: "100%",
          padding: "8px",
          userSelect: "none",
          opacity: disabled ? 0.7 : 1,
          cursor: disabled ? "not-allowed" : "pointer",
        }}
        control={
          <Checkbox
            checked={checked}
            icon={
              variant === "single" ? (
                <CheckIconCircle
                  color={
                    isHovered
                      ? "var(--mui-palette-options-hover-default-color)"
                      : "var(--mui-palette-options-default-color"
                  }
                />
              ) : (
                <CheckIcon
                  color={
                    isHovered
                      ? "var(--mui-palette-options-hover-default-color)"
                      : "var(--mui-palette-options-default-color"
                  }
                />
              )
            }
            checkedIcon={
              variant === "single" ? (
                <CheckFilledIconCircle color="var(--mui-palette-options-active-color" />
              ) : (
                <CheckFilledIcon color="var(--mui-palette-options-active-color" />
              )
            }
          />
        }
        label={
          <Box
            sx={{
              fontSize: "14px",
              ml: "4px",
              ...labelStyles,
              color: "var(--mui-palette-options-text-color)",
            }}
          >
            {children}
          </Box>
        }
      />
      {checked && openEnded && (
        <Box
          sx={{
            borderTop: "solid 1px rgba(12, 12, 36, 0.06)",
            p: "16px",
          }}
        >
          <TextField
            multiline
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            sx={{
              width: "100%",
              ".MuiInputBase-root": { padding: "10px 8px" },
            }}
            placeholder={choiceInputPlaceholderLabel || "Type your answer!"}
            onChange={(e) => {
              onChange?.(true, e.target.value);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
